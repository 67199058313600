var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"donor-list-view",attrs:{"fluid":"","size":"large"}},[_c('v-row',{staticClass:"mx-0 px-4"},[_c('div',{style:({
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginBottom: '10px'
			})},[_c('h1',[_vm._v("Donors")])]),_c('v-spacer'),_c('Roundup-Button',{staticClass:"mx-auto mt-3",attrs:{"color":"primary"},on:{"click":_vm.toggleCSVDialog}},[_vm._v("Invite Donors")])],1),_c('v-row',{staticClass:"mx-4 mt-6"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('Roundup-Button',_vm._g(_vm._b({style:({
						background: _vm.activeFilter.value
							? 'rgba(234, 237, 251) !important'
							: 'inherit'
					}),attrs:{"color":"white"}},'Roundup-Button',attrs,false),on),[_c('i',{staticClass:"fa-regular fa-filter"}),_vm._v(" "+_vm._s(_vm.activeFilter.value ? ("Filter: " + (_vm.activeFilter.value)) : 'Filter')+" ")])]}}])},[_c('v-list',{},[_c('v-list-item',{staticClass:"filter-option",on:{"click":_vm.clearActiveFilter}},[_c('v-list-item-title',[_vm._v("Clear Filters")])],1),_vm._l((_vm.filters),function(group,index){return _c('div',{key:index},[_c('v-divider',{staticStyle:{"width":"100% !important"}}),_c('p',{staticClass:"filter-group-name px-4"},[_vm._v(" "+_vm._s(group.groupName)+" ")]),_vm._l((group.options),function(item,index){return _c('v-list-item',{key:index,staticClass:"filter-option",on:{"click":function($event){return _vm.setActiveFilter(group.column, item.name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)})],2)})],2)],1),_c('v-text-field',{staticClass:"ml-4 mt-0 pt-1",staticStyle:{"max-width":"400px"},attrs:{"prepend-inner-icon":"fa-regular fa-magnifying-glass","placeholder":"Search by name, phone, or email","clearable":"","clear-icon":"fas fa-times"},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}}),_c('v-spacer'),_c('Roundup-Button',{attrs:{"color":"white","disabled":_vm.$refs.donorTable &&
					this.$refs.donorTable.$children[0].filteredItems
						.length === 0},on:{"click":_vm.handleDownload}},[_vm._v("Export "+_vm._s(_vm.$refs.donorTable && (_vm.tableSearch || _vm.activeFilter.value) ? this.$refs.donorTable.$children[0].filteredItems .length : '')+" Donors")])],1),_c('v-data-table',{ref:"donorTable",staticClass:"elevation-1 mx-4 table",attrs:{"headers":_vm.columns,"items":_vm.donors,"sort-desc":[false, true],"multi-sort":"","search":_vm.tableSearch || _vm.activeFilter.column,"custom-filter":_vm.filterSelection,"loading":_vm.isLoading,"loading-text":"Fetching your donors..."},on:{"current-items":function (items) { return (this$1.filteredRows = items); }},scopedSlots:_vm._u([{key:"item.user.email",fn:function(ref){
						var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navigateToDonorDetail(item)}}},[_vm._v(" "+_vm._s(item.user.email)+" ")])]}},{key:"item.actions",fn:function(ref){
						var item = ref.item;
return [(item.status === 'Active')?_c('Roundup-Button',{staticClass:"pause-button",staticStyle:{"min-width":"40px !important"},attrs:{"color":"white"},on:{"click":function($event){return _vm.pauseModal(item)}}},[_c('i',{staticClass:"fa-solid fa-pause mr-2",staticStyle:{"padding-top":"2px !important"}}),_vm._v("Pause ")]):_vm._e()]}}])}),_c('modal',{staticClass:"pause-modal",attrs:{"size":"medium"},model:{value:(_vm.showPauseModal),callback:function ($$v) {_vm.showPauseModal=$$v},expression:"showPauseModal"}},[(_vm.ui_to_pause)?_c('h4',{staticStyle:{"font-size":"1.25rem !important"}},[_vm._v(" Pause donations for "+_vm._s(_vm.ui_to_pause.donor_name ? _vm.ui_to_pause.donor_name : _vm.ui_to_pause.user && _vm.ui_to_pause.user.email)+"? ")]):_vm._e(),_c('p',[_vm._v(" If the donor wants to resume donations through RoundUp App, they will have to do so in their app. ")]),(_vm.messageResult)?_c('div',{staticClass:"alert alert-info",staticStyle:{"margin-top":"20px","width":"100%"}},[_vm._v(" "+_vm._s(_vm.messageResult)+" ")]):_vm._e(),_c('v-row',{staticClass:"pt-4"},[_c('Roundup-Button',{attrs:{"color":"white"},on:{"click":function($event){_vm.showPauseModal = false}}},[_vm._v(" Cancel")]),_c('v-spacer'),_c('Roundup-Button',{attrs:{"color":"red","loading":_vm.pauseFetching},on:{"click":_vm.onPauseUi}},[_vm._v("Pause")])],1)],1),_c('CSVDialog',{ref:"csvDialog",attrs:{"orgName":_vm.orgName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }