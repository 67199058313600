<template>
	<v-container fluid class="donor-list-view" size="large">
		<v-row class="mx-0 px-4">
			<div
				:style="{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '10px'
				}"
			>
				<h1>Donors</h1>
			</div>
			<v-spacer></v-spacer>
			<Roundup-Button
				class="mx-auto mt-3"
				color="primary"
				@click="toggleCSVDialog"
				>Invite Donors</Roundup-Button
			>
		</v-row>
		<v-row class="mx-4 mt-6">
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<Roundup-Button
						color="white"
						v-bind="attrs"
						v-on="on"
						:style="{
							background: activeFilter.value
								? 'rgba(234, 237, 251) !important'
								: 'inherit'
						}"
						><i class="fa-regular fa-filter"></i>
						{{
							activeFilter.value
								? `Filter: ${activeFilter.value}`
								: 'Filter'
						}}
					</Roundup-Button>
				</template>
				<v-list class="">
					<v-list-item
						class="filter-option"
						@click="clearActiveFilter"
					>
						<v-list-item-title>Clear Filters</v-list-item-title>
					</v-list-item>

					<div v-for="(group, index) in filters" :key="index">
						<v-divider style="width: 100% !important"></v-divider>
						<p class="filter-group-name px-4">
							{{ group.groupName }}
						</p>
						<v-list-item
							class="filter-option"
							v-for="(item, index) in group.options"
							:key="index"
							@click="setActiveFilter(group.column, item.name)"
						>
							<v-list-item-title>{{
								item.name
							}}</v-list-item-title>
						</v-list-item>
					</div>
				</v-list>
			</v-menu>
			<v-text-field
				class="ml-4 mt-0 pt-1"
				style="max-width: 400px"
				prepend-inner-icon="fa-regular fa-magnifying-glass"
				placeholder="Search by name, phone, or email"
				v-model="tableSearch"
				clearable
				clear-icon="fas fa-times"
			></v-text-field>
			<v-spacer></v-spacer>

			<Roundup-Button
				color="white"
				@click="handleDownload"
				:disabled="
					$refs.donorTable &&
						this.$refs.donorTable.$children[0].filteredItems
							.length === 0
				"
				>Export
				{{
					$refs.donorTable && (tableSearch || activeFilter.value)
						? this.$refs.donorTable.$children[0].filteredItems
								.length
						: ''
				}}
				Donors</Roundup-Button
			>
		</v-row>
		<v-data-table
			:headers="columns"
			:items="donors"
			:sort-desc="[false, true]"
			multi-sort
			class="elevation-1 mx-4 table"
			:search="tableSearch || activeFilter.column"
			:custom-filter="filterSelection"
			:loading="isLoading"
			loading-text="Fetching your donors..."
			@current-items="items => (this.filteredRows = items)"
			ref="donorTable"
		>
			<template v-slot:item.user.email="{ item }">
				<a
					@click="navigateToDonorDetail(item)"
					style="text-decoration: none"
				>
					{{ item.user.email }}
				</a>
			</template>
			<template v-slot:item.actions="{ item }">
				<Roundup-Button
					class="pause-button"
					v-if="item.status === 'Active'"
					color="white"
					style="min-width: 40px !important"
					@click="pauseModal(item)"
					><i
						class="fa-solid fa-pause mr-2"
						style="padding-top: 2px !important"
					></i
					>Pause
				</Roundup-Button>
			</template>
		</v-data-table>

		<modal v-model="showPauseModal" size="medium" class="pause-modal">
			<h4 v-if="ui_to_pause" style="font-size: 1.25rem !important">
				Pause donations for
				{{
					ui_to_pause.donor_name
						? ui_to_pause.donor_name
						: ui_to_pause.user && ui_to_pause.user.email
				}}?
			</h4>
			<p>
				If the donor wants to resume donations through RoundUp App, they
				will have to do so in their app.
			</p>
			<div
				v-if="messageResult"
				class="alert alert-info"
				style="margin-top: 20px; width: 100%"
			>
				{{ messageResult }}
			</div>
			<v-row class="pt-4">
				<Roundup-Button @click="showPauseModal = false" color="white">
					Cancel</Roundup-Button
				>
				<v-spacer></v-spacer>
				<Roundup-Button
					color="red"
					@click="onPauseUi"
					:loading="pauseFetching"
					>Pause</Roundup-Button
				>
			</v-row>
		</modal>

		<CSVDialog ref="csvDialog" :orgName="orgName" />
	</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get, numberFormat, incompleteStep, enums } from '@/utilities'
import table from '@/mixins/table'
import download from '@/mixins/download'
import Modal from '../../ui/Modal'

import UiButton from '../../ui/Button'
import UiToggle from '../../ui/Toggle'
import UiLoader from '../../ui/Loader'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import TokenList from '../../layout/TokenList'
import Screen from '../../ui/Screen'
import CSVDialog from './CSVDialog.vue'

let columns = [
	{
		text: 'Donor',
		value: 'donor_name'
	},
	{ text: 'Email', value: 'user.email' },
	{ text: 'Phone', value: 'user.phone' },
	{ text: 'Status', value: 'status' },
	{ text: 'Status Date', value: 'created_at' },
	{ text: 'Donor Type', value: 'donor_type' },
	{ text: ' ', value: 'actions', sortable: false }
]

export default {
	name: 'donor-list-view',
	components: {
		UiButton,
		Screen,
		Modal,
		UiToggle,
		UiLabel,
		TokenList,
		UiLoader,
		UiInput,
		CSVDialog
	},
	mixins: [table, download],
	data() {
		return {
			tableSearch: null,
			columns,
			user: null,
			isSaving: false,
			messageResult: null,
			filteredRows: [],
			filters: [
				{
					groupName: 'Type',
					column: 'donor_type',
					options: [
						{ name: 'One-Time' },
						{ name: 'Monthly Gift' },
						{ name: 'RoundUp' }
					]
				},
				{
					groupName: 'Status',
					column: 'status',
					options: [
						{ name: 'Active' },
						{ name: 'Inactive' },
						{ name: 'Paused' },
						{ name: 'Potential' }
					]
				}
			],
			activeFilter: { column: null, value: null },
			ui_to_pause: null,
			showPauseModal: false,
			pauseFetching: false
		}
	},
	computed: {
		...mapState({
			donors: ({ donorList }) => donorList.items,
			isLoading: ({ donorList }) => donorList.isFetching,
			total: ({ donorList }) => donorList.total,
			authToken: ({ me }) => me.token,
			me: ({ me }) => me
		}),
		orgName() {
			return this.me ? this.me.current.institution.name : null
		}
	},
	head: {
		title: {
			inner: 'Donors'
		}
	},
	methods: {
		...mapActions(['getDonors', 'pauseDonor']),
		setActiveFilter(column, value) {
			this.activeFilter = { column, value }
		},
		clearActiveFilter() {
			this.activeFilter = { column: null, value: null }
		},
		filterSelection(value, search, item) {
			if (this.activeFilter.column) {
				if (
					item[this.activeFilter.column].toLowerCase() !==
					this.activeFilter.value.toLowerCase()
				) {
					return false
				} else {
					if (this.activeFilter.column === search) {
						return true
					}
				}
			}

			return (
				value != null &&
				search != null &&
				typeof value === 'string' &&
				value
					.toString()
					.toLowerCase()
					.indexOf(search.toLowerCase()) !== -1
			)
		},
		navigateToDonorDetail(donor) {
			this.$store.commit('DONOR_DETAIL', { result: donor })
			let donorId = donor.user.id

			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donorId }
			})
		},
		paramsChanged(params) {
			this.getDonors(params)
		},
		handleDownload() {
			window.analytics.track('download_donor_report')
			var id_list = this.$refs.donorTable.$children[0].filteredItems.map(
				item => item.id
			)

			this.postParameters(
				'/donors/subset/csv',
				{ id_list },
				this.authToken
			)
		},
		toggleCSVDialog() {
			this.$refs.csvDialog.toggle()
		},
		pauseModal(ui) {
			this.ui_to_pause = ui
			this.showPauseModal = true
			this.messageResult = null
		},
		onPauseUi() {
			this.pauseFetching = true
			this.pauseDonor(this.ui_to_pause.id)
				.then(() => {
					this.pauseFetching = false
					this.showPauseModal = false
					this.ui_to_pause = null
				})
				.catch(e => {
					this.messageResult = e.message
					this.pauseFetching = false
					console.log(e.response)
				})
		},
		get,
		numberFormat,
		incompleteStep
	},
	mounted() {
		this.getDonors(this.getParameters())
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.filter-group-name {
	padding-top: 0.5rem !important;
	font-size: 0.75rem !important;
	color: $roundup-dark-grey;
}
.filter-option {
	&:hover {
		background-color: $roundup-bone !important;
		cursor: pointer !important;
	}
}

.pause-modal {
	h4 {
		color: $roundup-charcoal !important;
	}
	p {
		color: $roundup-dark-grey !important;
	}
}

.pause-button {
	border: 1px solid #747a8d !important;
	height: 32px !important;
	padding: 0 12px !important;
	color: #747a8d !important;
	font-size: 0.875rem !important;
}
</style>
